import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogCard = ({ title, image, uri, imageAlt, date }) => {
	return (
		<Col md={6}>
			<Link
				style={{ overflow: "hidden" }}
				className="  text-decoration-none "
				to={`/blog/${uri}`}
			>
				<div className=" .heading-animated  feature-box">
					<Card
						style={{
							borderRadius: "0px",
							border: "none",
							boxShadow: " 2px 4px 20px rgba(0, 0, 0, 0.15)",
							overflow: "hidden",
						}}
						className="w-100  mb-6 p-0"
					>
						<GatsbyImage
							style={{ height: "13rem", borderRadius: "0px" }}
							image={image}
							alt={imageAlt}
						/>
						<Card.Body className="bg-white ps-4">
							<h2 className="text-primary .heading-animated fw-light">
								{title}
							</h2>
							<hr className="bg-secondary mb-2 hr-big w-30 " />

							<p className="text-background">{date}</p>
						</Card.Body>
					</Card>
				</div>
			</Link>
		</Col>
	);
};

export default BlogCard;
