import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlogCard from "../components/blogCard";
import Navigation from "../components/navigation";
import { graphql, Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa";
import SVGBA1 from "../components/svg/ba1-svg";
import SVGBA2 from "../components/svg/ba2-svg";
import SVGBA3 from "../components/svg/ba3-svg";

export default function BlogPost({ data }) {
	const post = data?.wpPost;
	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/blog/${post.slug}`,
					id: `${siteUrl}/blog/${post.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<div className="position-relative">
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>
				<GatsbySeo
					title={post.title}
					description={post.blogSeo.metaDescription}
					openGraph={{
						url: `${siteUrl}/blog/${post.slug}`,
						title: `${post.title}`,
						description: post.blogSeo.metaDescription,
						type: "article",
						images: [
							{
								url: `${post.blogFields.featureImage.node.localFile.publicURL}`,
								width: `${post.blogFields.featureImage.node.localFile.childImageSharp?.original.width}`,
								height: `${post.blogFields.featureImage.node.localFile.childImageSharp?.original.height}`,
								alt: `${post.blogFields.featureImage.node.altText}`,
							},
						],
					}}
				/>
				<SVGBA1
					style={{ zIndex: "-1" }}
					className="position-absolute d-none d-lg-block top-10 start-0"
				/>
				<SVGBA3
					style={{ zIndex: "-1" }}
					className="position-absolute d-none d-lg-block bottom-0 start-0"
				/>
				<Container
					style={{ minHeight: "100vh" }}
					className="position-relative d-none d-lg-block  py-0 my-0 px-0 mx-0"
					fluid
				>
					<Row className="position-absolute justify-content-end w-100 top-0 end-0 vh-100">
						<Col className="pe-0 me-0 position-relative" lg={8}>
							{" "}
							<div
								style={{
									borderTopLeftRadius: "8vh",
									borderBottomRightRadius: "8vh",
									zIndex: "0",
								}}
								className="bg-primary position-absolute w-100 top-0 end-0 vh-100  "
							>
								<SVGBA2
									style={{ zIndex: "-1" }}
									className="position-absolute d-none d-lg-block top-10 h-80 end-0"
								/>
							</div>
						</Col>
					</Row>

					<div className="position-absolute top-0 w-100">
						<Navigation />
					</div>

					<Row className="mx-0 px-0 justify-content-end h-100">
						<Col className="pe-0 me-0" lg={8}>
							<div
								style={{
									paddingTop: "11vh",
									borderTopLeftRadius: "8vh",
									borderBottomRightRadius: "8vh",
								}}
							>
								<Row className="justify-content-center">
									<Col xl={10}>
										<div
											style={{
												boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
												top: "5rem",
											}}
											className=" px-0 mx-0 pb-6 bg-white position-relative  start--20"
										>
											<section className="pt-6 px-lg-6 px-3">
												<Row>
													<Col>
														<h1 className="text-primary mb-2">{post.title}</h1>
														<hr className="bg-secondary  hr-big w-30 " />
														<p className="text-background ">{post.dateGmt}</p>
													</Col>
												</Row>

												<Row>
													<Col>
														<div className="blog-post-content ">
															{parse(post.content)}
														</div>
													</Col>
												</Row>
											</section>
										</div>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
					<Row className="py-7 justify-content-center pt-9">
						<Col className="pb-3" xs={12}>
							{" "}
							<h2 className="text-center text-primary py-4">Read also</h2>
						</Col>
						<Col lg={8}>
							<Row>
								{data.blogPosts?.nodes?.map((post) => (
									<BlogCard
										key={post.id}
										title={post.title}
										uri={post.slug}
										date={post.dateGmt}
										image={
											post.blogFields.featureImage.node.localFile
												.childImageSharp.gatsbyImageData
										}
										imageAlt={post.blogFields.featureImage.node.altText}
									></BlogCard>
								))}
							</Row>
						</Col>
					</Row>
				</Container>
				<Container
					fluid
					className="position-relative d-lg-none  py-0 my-0 px-0 mx-0"
				>
					<div className=" w-100">
						<Navigation />
					</div>
					<Container>
						<Row className="mx-0 px-0 pt-6 justify-content-end h-100">
							<Col className="pe-0 me-0" lg={8}>
								<Row className="justify-content-center">
									<Col xl={10}>
										<div
											style={{
												boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
												top: "5rem",
											}}
											className=" px-0 mx-0 pb-6 bg-white   "
										>
											<section className="pt-6 px-lg-6 px-3">
												<Row>
													<Col>
														<p className="text-primary cabin  fs-1 mb-2">
															{post.title}
														</p>
														<hr className="bg-secondary  hr-big w-30 " />
														<p className="text-background ">{post.dateGmt}</p>
													</Col>
												</Row>

												<Row>
													<Col>
														<div className="blog-post-content ">
															{parse(post.content)}
														</div>
													</Col>
												</Row>
											</section>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="py-7 justify-content-center pt-9">
							<Col className="pb-3" xs={12}>
								{" "}
								<h2 className="text-center text-primary py-4">Read also</h2>
							</Col>
							<Col lg={8}>
								<Row>
									{data.blogPosts?.nodes?.map((post) => (
										<BlogCard
											key={post.id}
											title={post.title}
											uri={post.slug}
											date={post.dateGmt}
											image={
												post.blogFields.featureImage.node.localFile
													.childImageSharp.gatsbyImageData
											}
											imageAlt={post.blogFields.featureImage.node.altText}
										></BlogCard>
									))}
								</Row>
							</Col>
						</Row>
					</Container>
				</Container>
			</div>
		</Layout>
	);
}

export const blogData = graphql`
	query ($id: String!) {
		wpPost(id: { eq: $id }) {
			dateGmt(formatString: "DD.MM.yyyy")
			id
			slug
			title
			uri
			excerpt
			content
			blogSeo {
				metaDescription
			}
			blogFields {
				featureImage {
					node {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: CONSTRAINED
								)
							}
						}
					}
				}
			}
		}
		blogPosts: allWpPost(
			sort: { fields: dateGmt, order: DESC }
			filter: { title: { ne: "Website Images" }, id: { ne: $id } }
		) {
			nodes {
				dateGmt(formatString: "DD.MM.yyyy")
				id
				title
				slug
				excerpt
				blogFields {
					featureImage {
						node {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										quality: 100
										placeholder: BLURRED
										layout: CONSTRAINED
									)
								}
							}
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
